import { css } from 'styled-components';

export interface WithTypographyProps {
  color?: string;
  align?: 'center' | 'left' | 'right';
  underline?: boolean;
  lineHeight?: number;
  fontWeight?: 'lighter' | 'regular' | 'bold';
  uppercase?: boolean;
  inverted?: boolean;
}

export const withTypography = ({
  color,
  align,
  underline,
  lineHeight,
  fontWeight,
  uppercase,
}: WithTypographyProps) => css`
  ${color &&
    css`
      color: ${color};

      * {
        color: ${color};
      }
    `};

  ${align &&
    css`
      text-align: ${align || 'none'};
    `};

  ${underline &&
    css`
      text-decoration: underline;
    `};

  ${lineHeight &&
    css`
      line-height: ${lineHeight};
    `};

  ${fontWeight &&
    css`
      font-weight: ${fontWeight || 'regular'};
    `};

  ${uppercase &&
    css`
      text-transform: uppercase;
    `}
`;
