import { css } from 'styled-components';

const getSpaceAfter = (defaultSpace: number, spaceAfter?: number) => {
  return spaceAfter !== undefined && spaceAfter >= 0
    ? spaceAfter
    : defaultSpace;
};

export const withTitleStyle = ({ spaceAfter }: any) => css`
  ${({ theme }) => css`
    ${theme.fontStyles.title.fontFamily &&
      css`
        font-family: ${theme.fontStyles.title.fontFamily};
      `};
    font-size: ${theme.fontSizes.title.xs};
    font-weight: 400;
    letter-spacing: ${theme.fontStyles.title.letterSpacing};
    line-height: normal;
    margin: 0;
    padding: 0;
    margin-bottom: ${theme.ruler[getSpaceAfter(10, spaceAfter)]}px;
    color: ${theme.fontColors.secondary};

    ${theme.media.md`
        font-size: ${theme.fontSizes.title.md};
      `};

    ${theme.media.lg`
        font-size: ${theme.fontSizes.title.lg};
      `};
  `}
`;

export const withH1Style = ({ spaceAfter }: any) => css`
  ${({ theme }) => css`
    ${theme.fontStyles.h1.fontFamily &&
      css`
        font-family: ${theme.fontStyles.h1.fontFamily};
      `};
    font-size: ${theme.fontSizes.h1.xs};
    font-weight: 400;
    margin: 0;
    padding: 0;
    margin-bottom: ${theme.ruler[getSpaceAfter(10, spaceAfter)]}px;
    color: ${theme.fontColors.secondary};
    line-height: ${theme.fontStyles.h1.lineHeight};
    text-transform: ${theme.fontStyles.h1.textTransform};
    letter-spacing: ${theme.fontStyles.h1.letterSpacing};

    ${theme.media.md`
        font-size: ${theme.fontSizes.h1.md};
      `};

    ${theme.media.lg`
        font-size: ${theme.fontSizes.h1.lg};
      `};
  `}
`;

export const withH2Style = ({ spaceAfter }: any) => css`
  ${({ theme }) => css`
    ${theme.fontStyles.h2.fontFamily &&
      css`
        font-family: ${theme.fontStyles.h2.fontFamily} !important;
      `};
    font-size: ${theme.fontSizes.h2.xs};
    font-weight: 400;
    margin: 0;
    padding: 0;
    margin-bottom: ${theme.ruler[getSpaceAfter(8, spaceAfter)]}px;
    color: ${theme.fontColors.secondary};
    line-height: ${theme.fontStyles.h2.lineHeight};
    text-transform: ${theme.fontStyles.h2.textTransform};
    letter-spacing: ${theme.fontStyles.h2.letterSpacing};

    ${theme.media.md`
        font-size: ${theme.fontSizes.h2.md};
      `};

    ${theme.media.lg`
        font-size: ${theme.fontSizes.h2.lg};
      `};
  `}
`;

export const withH3Style = ({ spaceAfter }: any) => css`
  ${({ theme }) => css`
    ${theme.fontStyles.h3.fontFamily &&
      css`
        font-family: ${theme.fontStyles.h3.fontFamily};
      `};
    font-size: ${theme.fontSizes.h3.xs};
    font-weight: 400;
    margin: 0;
    padding: 0;
    margin-bottom: ${theme.ruler[getSpaceAfter(8, spaceAfter)]}px;
    color: ${theme.fontColors.secondary};
    line-height: ${theme.fontStyles.h3.lineHeight};
    text-transform: ${theme.fontStyles.h3.textTransform};
    letter-spacing: ${theme.fontStyles.h3.letterSpacing};

    ${theme.media.md`
        font-size: ${theme.fontSizes.h3.md};
      `};

    ${theme.media.lg`
        font-size: ${theme.fontSizes.h3.lg};
      `};
  `}
`;

export const withH4Style = ({ spaceAfter }: any) => css`
  ${({ theme }) => css`
    ${theme.fontStyles.h4.fontFamily &&
      css`
        font-family: ${theme.fontStyles.h4.fontFamily};
      `};
    font-size: ${theme.fontSizes.h4.xs};
    font-weight: 400;
    margin: 0;
    padding: 0;
    margin-bottom: ${theme.ruler[getSpaceAfter(6, spaceAfter)]}px;
    color: ${theme.fontColors.secondary};
    line-height: ${theme.fontStyles.h4.lineHeight};
    text-transform: ${theme.fontStyles.h4.textTransform};
    letter-spacing: ${theme.fontStyles.h4.letterSpacing};

    ${theme.media.md`
        font-size: ${theme.fontSizes.h4.md};
      `};

    ${theme.media.lg`
        font-size: ${theme.fontSizes.h4.lg};
      `};
  `}
`;

export const withH5Style = ({ spaceAfter }: any) => css`
  ${({ theme }) => css`
    ${theme.fontStyles.h5.fontFamily &&
      css`
        font-family: ${theme.fontStyles.h5.fontFamily};
      `};
    font-size: ${theme.fontSizes.h5.xs};
    font-weight: 400;
    margin: 0;
    padding: 0;
    margin-bottom: ${theme.ruler[getSpaceAfter(4, spaceAfter)]}px;
    color: ${theme.fontColors.secondary};
    line-height: ${theme.fontStyles.h5.lineHeight};
    text-transform: ${theme.fontStyles.h5.textTransform};
    letter-spacing: ${theme.fontStyles.h5.letterSpacing};

    ${theme.media.md`
        font-size: ${theme.fontSizes.h5.md};
      `};

    ${theme.media.lg`
        font-size: ${theme.fontSizes.h5.lg};
      `};
  `}
`;

export const withH6Style = ({ spaceAfter }: any) => css`
  ${({ theme }) => css`
    ${theme.fontStyles.h6.fontFamily &&
      css`
        font-family: ${theme.fontStyles.h6.fontFamily};
      `};
    font-size: ${theme.fontSizes.h6.xs};
    font-weight: 400;
    margin: 0;
    padding: 0;
    margin-bottom: ${theme.ruler[getSpaceAfter(4, spaceAfter)]}px;
    color: ${theme.fontColors.secondary};
    line-height: ${theme.fontStyles.h6.lineHeight};
    text-transform: ${theme.fontStyles.h6.textTransform};
    letter-spacing: ${theme.fontStyles.h6.letterSpacing};

    ${theme.media.md`
        font-size: ${theme.fontSizes.h6.md};
      `};

    ${theme.media.lg`
        font-size: ${theme.fontSizes.h6.lg};
      `};
  `}
`;
